"use client";
import React, { createContext, useContext, useEffect, useState } from "react";
import {
  decodeJWT,
} from "@phpcreation/frontend-utils-react-nextjs-bundle/utils/functions";
import { usePathname } from "next/navigation";

// Create the context
const CSRFContext = createContext<{ csrf: string; csrfJwt: string }>({
  csrf: "",
  csrfJwt: "",
});

// Create the provider component
export default function CSRFProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [csrf, setCsrf] = useState<string>("");
  const [csrfJwt, setCsrfJwt] = useState<string>("");
  const pathName = usePathname();

  async function fetchNewCSRF() {
    const response = await fetch("/api/csrf/generate?pathName=" + pathName);
    const data = await response.json();
    setCsrfJwt(data);
  }

  useEffect(() => {
    async function fetchCSRF() {
      const response = await fetch("/api/csrf/get");
      const data = await response.json();

      setCsrf(data.value);

      // To get the csrf token with the right url
      fetchNewCSRF();
    }

    fetchCSRF();
  }, []);

  useEffect(() => {
    if (pathName) {
      fetchNewCSRF();
    }
  }, [pathName]);

  return (
    <CSRFContext.Provider value={{ csrf, csrfJwt }}>
      {children}
    </CSRFContext.Provider>
  );
}

// Custom hook to use the CSRFContext
export const useCSRF = () => {
  const context = useContext(CSRFContext);
  /* if (!context) {
    throw new Error("useCSRF must be used within a CSRFProvider");
  } */
  return context;
};
