"use client";

import {
  CallAPI,
  CallAPIURL,
} from "@phpcreation/frontend-utils-react-nextjs-bundle/utils/helpers";

// React
import React, { createContext, useContext, useEffect, useState } from "react";

import { UserContext, UserContextType } from "./UserContext";
import { getDefaultIds } from "@/lib/GetDefaultIds";
import { errorHandler } from "@phpcreation/frontend-utils-react-nextjs-bundle/utils";

// Tenant
import { useTenant } from "@phpcreation/frontend-config-react-nextjs-bundle/contexts";
import { useCSRF } from "@/contexts/CSRFContext";

export type TimesheetContextType = {
  currentTimesheet: string;
  currentTaskId: string;
  currentTaskName: string;
  timeSinceBeginning: string;
  defaultIds: any;
  handleGetCurrentTimesheet: () => void;
};

const TimesheetContext = createContext<TimesheetContextType>({
  currentTimesheet: "",
  currentTaskId: "",
  currentTaskName: "",
  timeSinceBeginning: "",
  defaultIds: {},
  handleGetCurrentTimesheet: () => {},
});

const TimesheetContextProvider = ({ children }: { children: any }) => {
  const tenant = useTenant();
  const { csrfJwt } = useCSRF();

  const [currentTimesheet, setCurrentTimesheet] = useState("");
  const [currentTaskId, setCurrentTaskId] = useState("");
  const [currentTaskName, setCurrentTaskName] = useState("");
  const [datetimeStart, setDatetimeStart] = useState("");
  const [timeSinceBeginning, setTimeSinceBeginning] = useState("");

  const [defaultIds, setDefaultIds] = useState<any>({});
  const defaultValuesList = [
    "TIMESHEET_TYPES",
    "TIMESHEET_MANUAL_ENTRY_MODES",
    "TIMESHEET_STATUS_IN_PROGRESS",
    "TIMESHEET_RATIO",
    "TIMESHEET_PUNCH_ENTRY_MODES",
  ];

  const { idEmployee } = useContext<UserContextType>(UserContext);

  const handleGetCurrentTimesheet = async () => {
    try {
      if (!idEmployee || idEmployee === -1) return;

      const { data } = await CallAPI(
        "GET",
        tenant,
        CallAPIURL.timesheets.get,
        JSON.stringify({
          page: "1",
          itemsPerPage: "30",
          employee: idEmployee,
          status: defaultIds.values.TIMESHEET_STATUS_IN_PROGRESS,
        }),
        JSON.stringify({
          csrf: csrfJwt,
        })
      );

      const arr = data.response["hydra:member"];

      if (!arr) {
        setCurrentTimesheet("");
        setCurrentTaskId("");
        setCurrentTaskName("");
        setDatetimeStart("");

        return;
      }

      if (arr?.length > 0) {
        setCurrentTimesheet(String(arr[0].id));
        setCurrentTaskId(String(arr[0].task.id));
        setCurrentTaskName(String(arr[0].task.toString));
        setDatetimeStart(String(arr[0].datetimeStart));
      } else {
        setCurrentTimesheet("");
        setCurrentTaskId("");
        setCurrentTaskName("");
        setDatetimeStart("");
      }

      return arr[0];
    } catch (err: any) {
      errorHandler(err, "Error fetching timesheet: ");
    }
  };

  useEffect(() => {
    if (defaultValuesList && window.self === window.top) {
      getDefaultIds(defaultValuesList).then((data) => {
        setDefaultIds(data);
      });
    }
  }, []);

  useEffect(() => {
    // Update every 60 seconds (60000 milliseconds)
    const interval = setInterval(() => {
      setTimeSinceBeginning(getTimeSince(datetimeStart));
    }, 60000);

    // Initial immediate update
    setTimeSinceBeginning(getTimeSince(datetimeStart));

    // Clear interval on component unmount
    return () => clearInterval(interval);
  }, [datetimeStart]);

  const getTimeSince = (dateString: string): string => {
    const givenDate = new Date(dateString);
    const currentDate = new Date();

    // Calculate the difference in milliseconds
    const diffMs = currentDate.getTime() - givenDate.getTime();

    // Convert to hours and minutes
    const hours = Math.floor(diffMs / (1000 * 60 * 60));
    const minutes = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60));

    // Return formatted time
    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}`;
  };

  useEffect(() => {
    if (
      idEmployee &&
      idEmployee !== -1 &&
      defaultIds &&
      JSON.stringify(defaultIds) !== "{}" &&
      csrfJwt
    ) {
      handleGetCurrentTimesheet();
    }
  }, [idEmployee, defaultIds, csrfJwt]);

  return (
    <TimesheetContext.Provider
      value={{
        currentTimesheet,
        currentTaskId,
        currentTaskName,
        timeSinceBeginning,
        defaultIds,
        handleGetCurrentTimesheet,
      }}
    >
      {children}
    </TimesheetContext.Provider>
  );
};

export { TimesheetContext, TimesheetContextProvider };
