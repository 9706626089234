"use client";

// React
import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  useCallback,
  useMemo,
  Dispatch,
  SetStateAction,
} from "react";

// Next
import { useSearchParams } from "next/navigation";

// Sentry
import * as Sentry from "@sentry/nextjs";

// Utils
import { RolesContext, RolesContextType } from "@/contexts/RolesContext";
import { UserContext, UserContextType } from "@/contexts/UserContext";
import {
  CallAPI,
  CallAPIURL,
} from "@phpcreation/frontend-utils-react-nextjs-bundle/utils/helpers";
import { storeToCache } from "@/lib/GetCache";
import { CacheKeys } from "@/lib/CacheKeys";
import { DecodedJwt, KVSContext, KVSContextType } from "@/contexts/KVSContext";
import { DropdownObject, Filters, Project } from "@/utils/types/common";
import { getPaginateDropdown } from "@/lib/GetPaginateDropdown";
import cookie from "js-cookie";
import { jwtDecode } from "jwt-decode";
import { logout } from "@phpcreation/frontend-utils-react-nextjs-bundle/utils/helpers";
import { errorHandler } from "@phpcreation/frontend-utils-react-nextjs-bundle/utils";
import { getDefaultIds } from "@/lib/GetDefaultIds";

// Tenant
import { useTenant } from "@phpcreation/frontend-config-react-nextjs-bundle/contexts";
import { useCSRF } from "@/contexts/CSRFContext";

type ContextType = {
  csrfJwt: string;
  tableData: Array<punch>;
  dataUnfiltered: Array<punch>;
  searchFilters: (search?: string) => void;
  pageMax: number;
  isLoading: boolean;
  pageString: string;
  descOptionnal: boolean;
  arrayRoles: any;
  filters: Filters;
  setFilters: (filters: Filters) => void;
  arrayProject: Array<Project>;
  dropdownLoading: boolean;
  employeeDropdown: DropdownObject;
  setEmployeeDropdown: Dispatch<SetStateAction<DropdownObject>>;
  setTableData: (data: Array<punch>) => void;
  updateTask: (id: string) => void;
  entriesPerPage: number;
  setEntriesPerPage: (entriesPerPage: number) => void;
  setChangingEntriesManual: (changingEntriesManual: boolean) => void;
  currentPage: number;
  setCurrentPage: (currentPage: number) => void;
  setChangingCurrentPageManual: (changingCurrentPageManual: boolean) => void;
};

const TaskManagerContext = createContext<ContextType>({
  csrfJwt: "",
  tableData: [],
  dataUnfiltered: [],
  searchFilters: () => {},
  pageMax: 1,
  isLoading: true,
  pageString: "0 - 0 / 0",
  descOptionnal: false,
  arrayRoles: {},
  filters: {
    filterEmployee: "",
    filterProject: "",
    filterStatus: "",
    filterSector: "",
    filterPriority: "",
  },
  setFilters: () => {},
  arrayProject: [],
  dropdownLoading: true,
  employeeDropdown: {} as DropdownObject,
  setEmployeeDropdown: () => {},
  setTableData: () => {},
  updateTask: () => {},
  entriesPerPage: 25,
  setEntriesPerPage: () => {},
  setChangingEntriesManual: () => {},
  currentPage: 1,
  setCurrentPage: () => {},
  setChangingCurrentPageManual: () => {},
});

type punch = {
  id: string;
  title: string;
  description: string;
  deadline: string;
  status: string;
  estimatedTime: number;
  totalHours: number;
  progress: number;
  project: string;
  daysLeft: number;
};

const TaskManagerContextProvider: React.FC<any> = ({ children }) => {
  const searchParams = useSearchParams();

  const { csrfJwt } = useCSRF();

  const tenant = useTenant();

  const [defaultIds, setDefaultIds] = useState<any>({ firstRender: true });
  const defaultValuesList = ["TASK_STATUS_IN_PROGRESS", "TASK_STATUS_APPROVED"];

  const [dataUnfiltered, setDataUnfiltered] = useState<Array<punch>>([]);
  const [tableData, setTableData] = useState<Array<punch>>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [changingCurrentPageManual, setChangingCurrentPageManual] =
    useState(false);
  const [pageMax, setPageMax] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(25);
  const [changingEntriesManual, setChangingEntriesManual] = useState(false);
  const [pageString, setPageString] = useState("0 - 0 / 0");
  const [isLoading, setIsLoading] = useState(false);
  const [descOptionnal, setDescOptionnal] = useState(false);
  const [filters, setFilters] = useState<Filters>({
    filterEmployee: "",
    filterProject: "",
    filterStatus: "",
    filterSector: "",
    filterPriority: "",
  });
  const [filtersLoading, setFiltersLoading] = useState({
    filterEmployee: true,
    filterProject: true,
    filterStatus: true,
    filterSector: true,
    filterPriority: true,
  });
  const [allFiltersLoading, setAllFiltersLoading] = useState(true);

  const [employeeDropdown, setEmployeeDropdown] = useState<DropdownObject>(
    {} as DropdownObject
  );
  const [projectsDropdown, setProjectsDropdown] = useState<DropdownObject>(
    {} as DropdownObject
  );
  /* const [taskDropdown, setTaskDropdown] = useState<DropdownObject>(
    {} as DropdownObject
  ); */
  const [dropdownLoading, setDropdownLoading] = useState(true);
  const [arrayProject, setArrayProject] = useState<Array<Project>>([]);

  const { kvs } = useContext<KVSContextType>(KVSContext);
  const { user, getUser, idEmployee, idPerson } =
    useContext<UserContextType>(UserContext);
  const { arrayRoles } = useContext<RolesContextType>(RolesContext);

  const getDropdownData = useCallback(async () => {
    let idUser = user.id;
    if (!idUser) {
      const jwtUser = cookie.get("currentUser");
      if (jwtUser) {
        try {
          const decodedJwt: DecodedJwt = jwtDecode(jwtUser);
          idUser = decodedJwt.user.id;
        } catch (error: any) {
          errorHandler(error, "Error decoding jwt token : ");
        }
      } else {
        Sentry.captureException("No user id found");
        logout(tenant);
      }
    }

    const fetchProject = async () => {
      try {
        const projects = await getDropdownFilter(
          false,
          arrayRoles?.ROLE_MOD_PROJECT_DROPDOWN,
          CallAPIURL.projects.getDropdown,
          projectsDropdown,
          setProjectsDropdown
        );

        // Should be removed till the end
        const res = await CallAPI(
          "GET",
          tenant,
          CallAPIURL.projects.getDropdown,
          JSON.stringify({
            page: "1",
            itemsPerPage: "25",
          }),
          JSON.stringify({
            csrf: csrfJwt,
          })
        );

        setArrayProject(res.data.response["hydra:member"]);
        storeToCache(
          tenant,
          idUser.toString(),
          CacheKeys.Dropdown.Project,
          projects
        );
      } catch (error: any) {
        errorHandler(error, `Error fetching project dropdown : `);
      }
    };

    try {
      if (csrfJwt) {
        fetchProject();
      }
      setDropdownLoading(false);
    } catch (error: any) {
      errorHandler(error, "Error fetching dropdown data : ");
    }
  }, [arrayRoles, csrfJwt]);

  const getDropdownFilter = useCallback(
    async (
      isRefetching: boolean,
      role: string,
      apiCall: string,
      dropdownObject: DropdownObject,
      setDropdownObject: Dispatch<SetStateAction<DropdownObject>>
    ) => {
      if (
        !arrayRoles ||
        JSON.stringify(arrayRoles) === "{}" ||
        !role ||
        !csrfJwt
      ) {
        return [];
      }

      return await getPaginateDropdown(
        apiCall,
        tenant,
        csrfJwt,
        dropdownObject,
        setDropdownObject,
        isRefetching
      );
    },
    [arrayRoles, csrfJwt]
  );

  const searchFilters = useCallback(
    async (search?: string) => {
      setIsLoading(true);

      try {
        setTableData([]);
        await CallAPI(
          "GET",
          tenant,
          CallAPIURL.projectTasks.taskManager.getListing,
          JSON.stringify({
            page: String(currentPage),
            itemsPerPage: String(entriesPerPage),
            receiver: filters.filterEmployee,
            status: filters.filterStatus,
            sector: filters.filterSector,
            priority: filters.filterPriority,
            project: filters.filterProject,
            "order[deadline]": "asc",
            search: search ?? "",
          }),
          JSON.stringify({
            csrf: csrfJwt,
          })
        ).then((res) => {
          if (res.data?.error?.status == 401) {
            logout(tenant);
          }
          if (res.data.error) {
            errorHandler(res.data.error, "Error fetching data: ");
          } else {
            var parsedData = res.data.response["hydra:member"].map(
              (item: any) => ({
                id: String(item.id),
                title: item.title,
                description: item.description,
                deadline: item.deadline,
                status: item.status.toString,
                estimatedTime: item.estimatedTime,
                totalHours: item.taskTotalTimeInHours,
                progress:
                  (100 * item.taskTotalTimeInHours) / item.estimatedTime,
                project: item.project.title,
                daysLeft: (
                  (new Date(item.deadline).getTime() -
                    new Date(Date.now()).getTime()) /
                  86400000
                ).toFixed(0),
              })
            );
            var p: number = (currentPage - 1) * entriesPerPage;
            var pstring: string = `${p + 1} - ${p + parsedData.length} / ${
              res.data.response["hydra:totalItems"]
            }`;

            setPageString(pstring);
            setPageMax(
              Math.ceil(res.data.response["hydra:totalItems"] / entriesPerPage)
            );
            setTableData(parsedData);
            setDataUnfiltered(parsedData);
            setCurrentPage(1);
          }
        });
        setIsLoading(false);
      } catch (err: any) {
        errorHandler(err, "Error fetching data: ");
      }
    },
    [currentPage, entriesPerPage, filters, searchParams]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateTask = async (id: string) => {
    const res = await CallAPI(
      "GET",
      tenant,
      CallAPIURL.projectTasks.get + "/" + id,
      null,
      JSON.stringify({
        csrf: csrfJwt,
      })
    );

    const updatedTask: punch = {
      id: String(res.data.response.id),
      title: res.data.response.title,
      description: res.data.response.description,
      deadline: res.data.response.deadline,
      status: res.data.response.status.toString,
      estimatedTime: res.data.response.estimatedTime,
      totalHours: res.data.response.taskTotalTimeInHours,
      progress:
        (100 * res.data.response.taskTotalTimeInHours) /
        res.data.response.estimatedTime,
      project: res.data.response?.project?.title || "",
      daysLeft: parseFloat(
        (
          (new Date(res.data.response.deadline).getTime() -
            new Date(Date.now()).getTime()) /
          86400000
        ).toFixed(0)
      ),
    };

    const newTableData = tableData.map((task) => {
      if (task.id === id) {
        return updatedTask;
      }
      return task;
    });

    setTableData(newTableData);
  };

  const convertToArray = (data: string | string[]) => {
    if (!data) {
      return "";
    }

    if (Array.isArray(data)) {
      return data;
    }

    if (!data.includes(",")) {
      return data;
    }
    return data.split(",");
  };
  useEffect(() => {
    if (kvs && kvs.value.toString() !== "1") {
      setDescOptionnal(true);
    }
  }, [kvs]);

  useEffect(() => {
    if (
      idPerson &&
      idEmployee &&
      idEmployee !== -1 &&
      arrayRoles &&
      JSON.stringify(arrayRoles) !== "{}"
    ) {
      getDropdownData();
    }
  }, [idPerson, idEmployee, arrayRoles]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setFilters({
        filterEmployee: convertToArray(searchParams.get("receiver") || ""),
        filterProject: convertToArray(searchParams.get("project") || ""),
        filterStatus: convertToArray(searchParams.get("status") || ""),
        filterSector: convertToArray(searchParams.get("sector") || ""),
        filterPriority: convertToArray(searchParams.get("priority") || ""),
      });

      setEntriesPerPage(
        parseInt(
          searchParams.get("itemsPerPage") ??
            localStorage.getItem("perPage") ??
            "25"
        )
      );
    } else {
      setEntriesPerPage(parseInt(searchParams.get("itemsPerPage") ?? "25"));
      setCurrentPage(parseInt(searchParams.get("page") ?? "1"));
    }

    // Didn't add filterEmployee and Status because they have a default value to set if empty in query string.
    setFiltersLoading((prev) => ({
      ...prev,
      filterProject: false,
      filterSector: false,
      filterPriority: false,
    }));

    if (defaultValuesList && window.self === window.top) {
      getDefaultIds(defaultValuesList).then((data) => {
        setDefaultIds(data);
      });
    }
  }, []);

  useEffect(() => {
    if (
      !filters?.filterStatus &&
      defaultIds &&
      JSON.stringify(defaultIds) !== "{}" &&
      !defaultIds.firstRender
    ) {
      setFilters({
        ...filters,
        filterStatus: [
          defaultIds.values.TASK_STATUS_IN_PROGRESS,
          defaultIds.values.TASK_STATUS_APPROVED,
        ],
      });
    }
    if (defaultIds?.firstRender) {
      setDefaultIds({});
    } else {
      setFiltersLoading((prev) => ({
        ...prev,
        filterStatus: false,
      }));
    }
  }, [defaultIds]);

  useEffect(() => {
    if (!user) {
      getUser();
    }
  }, [user]);

  useEffect(() => {
    if (
      !filters?.filterEmployee &&
      idEmployee &&
      idEmployee !== -1 &&
      idEmployee !== -2
    ) {
      setFilters({
        ...filters,
        filterEmployee: String(idEmployee),
      });
    }
    if (idEmployee !== -1) {
      setFiltersLoading((prev) => ({
        ...prev,
        filterEmployee: false,
      }));
    }
  }, [idEmployee]);

  useEffect(() => {
    if (
      entriesPerPage &&
      changingEntriesManual &&
      tableData.length > 0 &&
      !allFiltersLoading &&
      csrfJwt
    ) {
      console.log("searchFilters from entriesPerPage", entriesPerPage);
      searchFilters();
      setChangingEntriesManual(false);
    }
  }, [entriesPerPage, csrfJwt]);

  useEffect(() => {
    if (
      currentPage &&
      changingCurrentPageManual &&
      tableData.length > 0 &&
      !allFiltersLoading &&
      csrfJwt
    ) {
      console.log("searchFilters from currentPage", currentPage);
      searchFilters();
      setChangingCurrentPageManual(false);
    }
  }, [currentPage, csrfJwt]);

  useEffect(() => {
    if (
      !filtersLoading.filterEmployee &&
      !filtersLoading.filterProject &&
      !filtersLoading.filterStatus &&
      !filtersLoading.filterSector &&
      !filtersLoading.filterPriority &&
      csrfJwt
    ) {
      console.log("searchFilters from filtersLoading", filtersLoading);
      searchFilters();
      setFiltersLoading({
        filterEmployee: true,
        filterProject: true,
        filterStatus: true,
        filterSector: true,
        filterPriority: true
      })
      setAllFiltersLoading(false);
    }
  }, [filtersLoading, csrfJwt]);

  const contextValue = useMemo(
    () => ({
      csrfJwt,
      tableData,
      dataUnfiltered,
      searchFilters,
      pageMax,
      isLoading,
      pageString,
      descOptionnal,
      arrayRoles,
      filters,
      setFilters,
      arrayProject,
      dropdownLoading,
      employeeDropdown,
      setEmployeeDropdown,
      setTableData,
      updateTask,
      entriesPerPage,
      setEntriesPerPage,
      setChangingEntriesManual,
      currentPage,
      setCurrentPage,
      setChangingCurrentPageManual,
    }),
    [
      csrfJwt,
      tableData,
      dataUnfiltered,
      searchFilters,
      pageMax,
      isLoading,
      pageString,
      descOptionnal,
      arrayRoles,
      filters,
      setFilters,
      arrayProject,
      dropdownLoading,
      employeeDropdown,
      setEmployeeDropdown,
      setTableData,
      updateTask,
      entriesPerPage,
      setEntriesPerPage,
      setChangingEntriesManual,
      currentPage,
      setCurrentPage,
      setChangingCurrentPageManual,
    ]
  );

  return (
    <TaskManagerContext.Provider value={contextValue}>
      {children}
    </TaskManagerContext.Provider>
  );
};

const useTaskManagerContext = () => useContext(TaskManagerContext);

export { TaskManagerContextProvider, useTaskManagerContext };
