"use client";

// React
import React, { createContext, useContext, useEffect, useState } from "react";

// Sentry
import * as Sentry from "@sentry/nextjs";

// Context
import { UserContext, UserContextType } from "./UserContext";
import cookie from "js-cookie";
import { jwtDecode } from "jwt-decode";

export interface DecodedJwt {
  user: {
    id: number;
  };
}

// Utils
import {
  CallAPI,
  CallAPIURL,
} from "@phpcreation/frontend-utils-react-nextjs-bundle/utils/helpers";
import { getFromCache, storeToCache } from "@/lib/GetCache";
import { errorHandler } from "@phpcreation/frontend-utils-react-nextjs-bundle/utils";
import { logout } from "@phpcreation/frontend-utils-react-nextjs-bundle/utils/helpers";

// Tenant
import { useTenant } from "@phpcreation/frontend-config-react-nextjs-bundle/contexts";
import { useCSRF } from "@/contexts/CSRFContext";

type kvsType = {
  description: string;
  id: number;
  isEnabled: boolean;
  key: string;
  slug: string;
  value: string;
};

export type KVSContextType = {
  kvs: kvsType;
};

const KVSContext = createContext<KVSContextType>({
  kvs: {} as kvsType,
});

const KVSContextProvider = ({ children }: { children: any }) => {
  const { csrfJwt } = useCSRF();
  const tenant = useTenant();
  const { user } = useContext<UserContextType>(UserContext);
  const [kvs, setKvs] = useState<kvsType>({
    description: "",
    id: 0,
    isEnabled: false,
    key: "",
    slug: "",
    value: "",
  });

  const getKVS = async () => {
    try {
      let idUser = user.id;
      if (!idUser) {
        const jwtUser = cookie.get("currentUser");
        if (jwtUser) {
          try {
            const decodedJwt: DecodedJwt = jwtDecode(jwtUser);
            idUser = decodedJwt.user.id;
          } catch (error: any) {
            errorHandler(error, "Error decoding jwt: ");
            Sentry.captureException(error);
            return;
          }
        } else {
          Sentry.captureException("No user id found");
          logout(tenant);
          return;
        }
      }
      const cachedKVS = await getFromCache(tenant, idUser.toString(), "KVS");

      console.log("cachedKVS", cachedKVS);

      if (cachedKVS) {
        setKvs(cachedKVS);
        return cachedKVS;
      }

      const { data } = await CallAPI(
        "GET",
        tenant,
        CallAPIURL.users.logged_user_kvs.get,
        "",
        JSON.stringify({ csrf: csrfJwt })
      );

      if (data.error) {
        Sentry.captureException(data.error);
        return;
      }

      if (!data.response.keyValues || data.response.keyValues?.length === 0)
        return;

      const indexOfDescMinimum = data.response.keyValues.findIndex(
        (value: kvsType) =>
          value.key == "phpreaction.project.task_manager.description_minimum"
      );

      const ttl = new Date();
      ttl.setHours(ttl.getHours() + 24);

      let kvsDescMinimum = {
        description: "1",
        id: 0,
        isEnabled: false,
        key: "phpreaction.project.task_manager.description_minimum",
        slug: "1",
        value: "0",
      };

      if (data.response.keyValues[indexOfDescMinimum]) {
        kvsDescMinimum = data.response.keyValues[indexOfDescMinimum];
        setKvs(kvsDescMinimum);
      }

      await storeToCache(tenant, idUser.toString(), "KVS", kvsDescMinimum, ttl);

      return kvsDescMinimum;
    } catch (error: any) {
      errorHandler(error, "Error fetching user KVS");
    }
  };

  useEffect(() => {
    if (JSON.stringify(user) !== "{}" && csrfJwt) getKVS();
  }, [user, csrfJwt]);

  return <KVSContext.Provider value={{ kvs }}>{children}</KVSContext.Provider>;
};

export { KVSContext, KVSContextProvider };
